import {
  ref,
  readonly,
  computed,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useCustomerStore, BBRCustomerInterface } from '~/modules/customer/stores/customer';
import { useCart, UseCartErrors } from '~/modules/checkout/composables/useCart';
import { generateUserData } from '~/modules/customer/helpers/generateUserData';
import { useUiNotification } from '~/composables/useUiNotification';
import type {
  UseUserInterface,
  UseUserErrors,
  UseUserLoadParams,
  UseUserLoginParams,
  UseUserLogoutParams,
  UseUserRegisterParams,
  UseUserUpdateUserParams,
  UseUserChangePasswordParams,
  UseUserUpdateEmailParams,
} from './useUser';
import { useApi } from '~/composables';
import updateBbrCustomerPasswordGql from '@theme/customQueries/updateBbrCustomerPassword.gql';
import updateBbrCustomerEmailGql from '@theme/customQueries/updateBbrCustomerEmail.gql';
import updateCustomerGql from '@theme/customQueries/updateCustomer.gql';
import { useHybrisUser } from '~/modules/customer/composables/useUser/useHybrisUser';
import { useHybrisCart } from '~/modules/checkout/composables/useCart/useHybrisCart';
import { isDualRunning, setIsLoggedIn } from '~/bbrTheme/helpers/dualRunning';
import { useCellar } from '@cellar-services/composables/useCellar';

const formatCustomerAttributes = (customer) => {
  const data = { ...customer };

  data.custom_attributes.forEach(({ code, value }) => data[code] = value);

  return data;
};

/**
 * Allows loading and manipulating data of the current user.
 *
 * See the {@link UseUserInterface} for a list of methods and values available in this composable.
 */
export function useUser(): UseUserInterface {
  const customerStore = useCustomerStore();
  // @ts-ignore
  const { app, $vsf, $recaptcha } = useContext();
  const { setCart } = useCart();
  const { mutate } = useApi();
  const router = useRouter();
  const { i18n, localePath, localeRoute } = useContext();
  const { send: sendNotification } = useUiNotification();
  const loading: Ref<boolean> = ref(false);
  const errorsFactory = () : UseUserErrors => ({
    updateUser: null,
    register: null,
    login: null,
    logout: null,
    changePassword: null,
    load: null,
    updateCustomerEmail: null,
  });
  const error: Ref = ref(errorsFactory());

  const errorHybris = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
  });

  const { resetCellar } = useCellar();
  const { loadCartAndUser } = useHybrisUser(errorHybris);
  const { load: loadHybrisCart } = useHybrisCart(loading, error);

  const setUser = (newUser: BBRCustomerInterface) => {
    customerStore.user = newUser;
    Logger.debug('useUserFactory.setUser', newUser);
  };

  const resetErrorValue = () => {
    error.value = errorsFactory();
  };

  const updateCustomerEmail = async (params: UseUserUpdateEmailParams): Promise<void> => {

    try {
      loading.value = true;
      const { data, errors }: {
        data: { updateBbrCustomerEmail },
        errors,
      } = await mutate(updateBbrCustomerEmailGql, { email: params.newEmail });

      Logger.debug('[Result] ', { data });

      if (errors) {
        const allErrorMessages = errors.map((e) => e.message).join(',');
        Logger.error(allErrorMessages);
        error.value.updateCustomerEmail = allErrorMessages;
      } else {
        sendNotification({
          id: Symbol('email_update'),
          message: '',
          persist: false,
          title: app.i18n.t('We\'ve updated your email address') as string,
          type: 'success',
          icon: 'success',
        });
      }

      customerStore.user = data?.updateBbrCustomerEmail?.customer;
    } catch (err) {
      error.value.updateCustomerEmail = err;
      Logger.error('useUser/updateCustomerEmail', err);
    } finally {
      loading.value = false;
    }
  };

  // eslint-disable-next-line consistent-return
  const updateUser = async (params: UseUserUpdateUserParams) => {
    Logger.debug('[Magento] Update user information', { ...params.user });
    resetErrorValue();

    try {
      loading.value = true;

      const { data }: {
        data: { updateCustomer }
      } = await mutate(updateCustomerGql, { input: { ...params.user } });
      Logger.debug('[Result]:', { data });

      customerStore.user = data?.updateCustomer?.customer || {};
      error.value.updateUser = null;
      sendNotification({
        id: Symbol('user_update'),
        type: 'success',
        message: app.i18n.t('You have successfully updated your personal information.') as string,
        icon: 'success',
        persist: false,
        title: app.i18n.t(
          'Personal information updated'
        ) as string,
      });
    } catch (err) {
      error.value.updateUser = err;
      Logger.error('useUser/updateUser', err);
    } finally {
      loading.value = false;
    }
  };

  const logout = async ({ customQuery = {}, customHeaders = {} }: UseUserLogoutParams = {}) => {
    Logger.debug('[Magento] useUserFactory.logout');
    resetErrorValue();

    try {
      const apiState = app.context.$vsf.$magento.config.state;

      if (isDualRunning(app.$cookies)) {
        await app.context.$vsf.$hybris.api.logout();
      } else {
        await app.context.$vsf.$magento.api.revokeCustomerToken(customQuery, customHeaders);
      }

      app.$cookies.remove($vsf.$cellarServices.config.cookieNames.algoliaUserApiKey);

      setIsLoggedIn(app.$cookies, false);

      apiState.removeCustomerToken();
      apiState.removeCartId();
      setCart(null);
      error.value.logout = null;

      customerStore.$reset();
      resetCellar();

      // Load guest cart
      loadHybrisCart();
    } catch (err) {
      error.value.logout = err;
      Logger.error('useUser/logout', err);
    }
  };

  const load = async ({ customQuery = { customer: 'customer' }, customHeaders = {} }: UseUserLoadParams = {}) => {
    resetErrorValue();

    try {
      loading.value = true;

      try {
        if (isDualRunning(app.$cookies)) {
          Logger.debug('[Hybris] useUser.load');

          await loadCartAndUser();
        } else {
          Logger.debug('[Magento] useUser.load');
          const apiState = app.context.$vsf.$magento.config.state;

          if (!apiState.getCustomerToken()) {
            return null;
          }

          const { data } = await app.context.$vsf.$magento.api.customer(customQuery, customHeaders);

          Logger.debug('[Result]:', { data });

          customerStore.user = data?.customer ? formatCustomerAttributes(data.customer) : {};
        }
      } catch(err) {
        // eslint-disable-next-line no-void
        // @ts-ignore
        Logger.error('[Hybris] useUser.load - logging out current user', err);
        await logout();
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useUser/load', err);
    } finally {
      loading.value = false;
    }

    return customerStore.user;
  };

  const login = async ({ user: providedUser, customQuery, customHeaders }: UseUserLoginParams) : Promise<void> => {
    if (isDualRunning(app.$cookies)) {
      return loginWithHybris({ user: providedUser, customQuery, customHeaders });
    }

    Logger.debug('[Magento] useUser.login', providedUser);
    resetErrorValue();

    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;

      const { data, errors } = await app.$vsf.$magento.api.generateCustomerToken(
        {
          email: providedUser.email,
          password: providedUser.password,
          recaptchaToken: providedUser.recaptchaToken,
        },
        customQuery || {},
        customHeaders || {},
      );
      Logger.debug('[Result]:', { data });

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
        errors.forEach((registerError, i) => sendNotification({
          icon: 'error',
          id: Symbol(`registration_error-${i}`),
          message: registerError.message,
          persist: true,
          title: 'Registration error',
          type: 'danger',
        }));

        throw new Error(joinedErrors);
      }

      console.log('[setIsLoggedIn] Customer login.');
      customerStore.setIsLoggedIn(true);
      apiState.setCustomerToken(data.generateCustomerToken.token);

      // merge existing cart with customer cart
      // todo: move this logic to separate method
      const currentCartId = apiState.getCartId();
      const cart = await app.context.$vsf.$magento.api.customerCart();
      const newCartId = cart.data.customerCart.id;

      try {
        if (newCartId && currentCartId && currentCartId !== newCartId) {
          const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts(
            {
              mergeCarts: 'mergeCarts',
              sourceCartId: currentCartId,
              destinationCartId: newCartId,
            },
          );

          setCart(dataMergeCart.mergeCarts);

          apiState.setCartId(dataMergeCart.mergeCarts.id);
        } else {
          setCart(cart.data.customerCart);
        }
      } catch {
        // Workaround for Magento 2.4.4 mergeCarts mutation error related with Bundle products
        // It can be removed when Magento 2.4.5 will be release
        setCart(cart.data.customerCart);
      }

      error.value.login = null;
    } catch (err) {
      error.value.login = err;
      Logger.error('useUser/login', err);
    } finally {
      loading.value = false;
    }
  };

  const loginWithHybris = async ({ user: providedUser }: UseUserLoginParams) : Promise<void> => {
    Logger.debug('[Hybris] useUser.login', providedUser);
    resetErrorValue();

    try {
      loading.value = true;

      await app.context.$vsf.$hybris.api.login({
        username: providedUser.email,
        password: providedUser.password,
      });

      redirectIfCheckout();

      console.log('[setIsLoggedIn] loginWithHybris');
      customerStore.setIsLoggedIn(true);
      setIsLoggedIn(app.$cookies, true);

      // merge cart fx needs an existing JSESSIONID
      // const currentCartId = apiState.getCartId();

      await loadCartAndUser(true);

      error.value.login = null;
    } catch (err) {
      error.value.login = err;
      Logger.error('[Hybris] useUser/login', err);

      sendNotification({
        icon: 'danger',
        id: Symbol('login_error'),
        message: 'Unable to login, please try again',
        persist: false,
        title: 'Login error',
        type: 'danger',
      });
    } finally {
      loading.value = false;
    }
  };

  // eslint-disable-next-line consistent-return
  const register = async ({
    user: providedUser,
    customQuery,
    customHeaders
  }: UseUserRegisterParams): Promise<void> => {
    Logger.debug('[Magento] useUser.register', providedUser);
    resetErrorValue();

    try {
      loading.value = true;

      const {
        email,
        password,
        recaptchaToken,
        ...baseData
      } = generateUserData(providedUser);

      const { data, errors } = await app.$vsf.$magento.api.createCustomer(
        {
          email,
          password,
          recaptchaToken,
          ...baseData,
        },
        customQuery || {},
        customHeaders || {},
      );

      Logger.debug('[Result]:', { data });

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
        errors.forEach((registerError, i) => sendNotification({
          icon: 'error',
          id: Symbol(`registration_error-${i}`),
          message: registerError.message,
          persist: true,
          title: 'Registration error',
          type: 'danger',
        }));
        throw new Error(joinedErrors);
      }

      error.value.register = null;
      let loginRecaptchaToken = '';

      if ($recaptcha && recaptchaToken) {
        loginRecaptchaToken = await $recaptcha.execute('login');
      }

      const { customer: { customer_create_account_confirm } } = app.context.$vsf.$magento.config;

      if (customer_create_account_confirm) {
        return await new Promise((resolve) => {
          sendNotification({
            id: Symbol('registration_confirmation'),
            message: app.i18n.t(
              'You must confirm your account. Please check your email for the confirmation link.'
            ) as string,
            persist: true,
            title: 'Registration confirmation',
            type: 'success',
            icon: 'check',
          });

          resolve();
        });
      }

      await login({ user: { email, password, recaptchaToken: loginRecaptchaToken }, customQuery: {} })
    } catch (err) {
      error.value.register = err;
      Logger.error('useUser/register', err);
    } finally {
      loading.value = false;
    }
  };

  const redirectIfCheckout = (): void => {
    if(window.location.pathname === '/cart') {
      if(window.location.search.includes('redirectOnCheckout')) {
        setTimeout(() => {
          window.location.replace('/checkout/multi/delivery');
        }, 0)
      }
    }
  }

  // eslint-disable-next-line consistent-return
  const changePassword = async (params: UseUserChangePasswordParams) => {
    resetErrorValue();

    try {
      loading.value = true;
      const { data }: {
        data: { updateBbrCustomerPassword }
      } = await mutate(updateBbrCustomerPasswordGql, { password: params.newPassword });

      Logger.debug('[Result] ', { data });

      customerStore.user = data?.updateBbrCustomerPassword?.customer;

      sendNotification({
        id: Symbol('password_change'),
        message: '',
        persist: false,
        title: app.i18n.t('We\'ve updated your password') as string,
        type: 'success',
        icon: 'success',
      });
    } catch (err) {
      error.value.changePassword = err;
      Logger.error('useUser/changePassword', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    setUser,
    updateUser,
    register,
    login,
    logout,
    changePassword,
    updateCustomerEmail,
    redirectIfCheckout,
    load,
    loading: readonly(loading),
    error: readonly(error),
    user: computed(() => customerStore.user),
    isAuthenticated: computed(() => customerStore.isLoggedIn),
  };
}

export default useUser;
export * from './useUser';
